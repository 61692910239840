import React from 'react';
import { render } from 'react-dom';

const Login = () => {
  //Warning: This is not a good example of using React. This was created with a specific goal in mind of showing a warning if React wasn't
  //compiled, or was compiled incorrectly. You should not access the DOM like this in normal React usage.

  const noReactMessage = document.getElementById('noReactMessage');
  noReactMessage.style.display = 'none';

  let message = '';

  if (process.env.NODE_ENV !== 'production') {
    message = 'Warning: React has not been compiled in production mode.';
  } else {
    const reactWarning = document.getElementById('reactWarning');
    reactWarning.style.display = 'none';
  }

  return <div>{message}</div>;
};

render(<Login />, document.getElementById('login-app'));
